import { createStore } from 'vuex'
import router from '@/router/index.js'
import axios from 'axios'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  state: {
    debug: debug,
    checklist: null,
    loading: false,
    updating: false,
    appkey: process.env.VUE_APP_KEY,
    webservice: process.env.VUE_APP_BASEURL + 'checklists/' + process.env.VUE_APP_KEY + '.json'
  },
  // ..######...########.########.########.########.########...######.
  // .##....##..##..........##.......##....##.......##.....##.##....##
  // .##........##..........##.......##....##.......##.....##.##......
  // .##...####.######......##.......##....######...########...######.
  // .##....##..##..........##.......##....##.......##...##.........##
  // .##....##..##..........##.......##....##.......##....##..##....##
  // ..######...########....##.......##....########.##.....##..######.
  getters: {
    isLoading: state => {
      // return Boolean(state.checklist)
      return state.loading
    },
    isUpdating: state => {
      return state.updating
    },
    wasResultShown: state => {
      return state.checklist ? state.checklist.resultshown : false
    },
    getResultData: state => {
      return state.checklist && state.checklist.resultData ? state.checklist.resultData : {}
    },
    getTitle: state => {
      return state.checklist ? state.checklist.title : "Lade ..."
    },
    getSubtitle: state => {
      return state.checklist ? state.checklist.subtitle : ""
    },
    getLogo: state => {
      return state.checklist ? state.checklist.logo : ""
    },
    getLogoWidth: state => {
      return state.checklist && state.checklist.logowidth ? state.checklist.logowidth : 260
    },
    getLogoHeight: state => {
      return state.checklist && state.checklist.logoheight ? state.checklist.logoheight : 62
    },
    getLogoBase64: state => {
      return state.checklist ? "data:image/jpeg;base64," + state.checklist.logobytes : ""
    },
    getAlt: state => {
      return state.checklist ? state.checklist.alt : "Logo"
    },
    getLink: state => {
      return state.checklist ? state.checklist.link : "/"
    },
    getDescription: state => {
      return state.checklist ? state.checklist.description : ""
    },
    getPrivacy: state => {
      return state.checklist ? state.checklist.privacy : ""
    },
    getNote: state => {
      return state.checklist ? state.checklist.note : ""
    },
    getTextSteps: state => {
      return state.checklist && state.checklist.textsteps ? state.checklist.textsteps : ""
    },
    getTextPoints: state => {
      return state.checklist && state.checklist.textpoints ? state.checklist.textpoints : ""
    },
    getTextQuestions: state => {
      return state.checklist && state.checklist.textquestions ? state.checklist.textquestions : ""
    },
    getTextImprint: state => {
      return state.checklist ? state.checklist.textimprint : ""
    },
    getTextAccessibility: state => {
      return state.checklist ? state.checklist.textaccessibility : ""
    },
    getTextPrivacy: state => {
      return state.checklist ? state.checklist.textprivacy : ""
    },
    getLinkPrivacy: state => {
      return state.checklist ? state.checklist.linkprivacy : ""
    },
    getLinkImprint: state => {
      return state.checklist ? state.checklist.linkimprint : ""
    },
    getLinkAccessibility: state => {
      return state.checklist ? state.checklist.linkaccessibility : ""
    },
    getLinkAccessibilityContact: state => {
      return state.checklist ? state.checklist.linkaccessibilitycontact + '?apptitle=' + process.env.VUE_APP_TITLE.replaceAll(' ', '%20') + '&appurl=' + encodeURI(window.location.href) : ""
    },
    getChapter: (state, getters) => (c) => {
      if (!state.checklist || !state.checklist.chapters[c]) {
        return {}
      }
      // if chapter is not used or no question in that chapter is used
      // disabled chapters are also delivered
      if (!getters.isUsed(state.checklist.chapters[c])
        || state.checklist.chapters[c].questions.every((question) => !getters.isUsed(question))) {
        return {}
      } else {
        return state.checklist.chapters[c]
      }
    },
    getUsedChapterIndices: state => {
      return state.checklist && state.checklist.usedChapterIndices ? state.checklist.usedChapterIndices : []
    },
    showProgressBar: state => {
      return state.checklist && state.checklist.showprogressbar ? state.checklist.showprogressbar : false
    },
    getStartChapterCounterAt: state => {
      return state.checklist && state.checklist.startchaptercounterat ? state.checklist.startchaptercounterat : 0
    },
    getQuestion: (state, getters) => (c, q) => {
      if (!state.checklist || !state.checklist.chapters[c] || !state.checklist.chapters[c].questions[q]) {
        return {}
      }
      if (!getters.isUsed(state.checklist.chapters[c].questions[q])) {
        return {}
      } else {
        return state.checklist.chapters[c].questions[q]
      }
    },
    getQuestions: (state, getters) => (c) => {
      if (!state.checklist || !state.checklist.chapters[c]) {
        return []
      }
      return state.checklist.chapters[c].questions
        .map((question, index) => {
          question.index = index
          return question
        })
        .filter((question) => (getters.isUsed(question)))

    },
    getChapterTitles: (state, getters) => {
      return getters.getFilteredChapters.map((chapter) => {
        return chapter.title
      })
    },
    getAnswers: (state) => {
      return state.checklist ? state.checklist.answers : {}
    },

    getFilteredChapters: (state) => {
      return state.checklist && state.checklist.filteredChapters ? state.checklist.filteredChapters : []
    },

    getDecisionArray: (state) => {
      return state.checklist && state.checklist.decisionArray ? state.checklist.decisionArray : []
    },

    getResultTitle: state => {
      return state.checklist && state.checklist.resulttitle ? state.checklist.resulttitle : "Bericht"
    },
    getResultIntro: state => {
      return state.checklist && state.checklist.resultintro ? state.checklist.resultintro : ""
    },

    getResultLists: (state) => {
      return state.checklist && state.checklist.resultLists ? state.checklist.resultLists : []
    },

    isUsed: (state, getters) => (obj) => {
      // console.log('isUsed');
      let debug = false
      if (!state.checklist || !obj) {
        return false
      }

      if (!obj.include_if && !obj.exclude_if
        || obj.include_if === '' && obj.exclude_if === '') {
        return true
      } else {
        let included, excluded, inclusionCriteria, exclusionCriteria
        let decisionArray = getters.getDecisionArray
        if (obj.include_if && obj.include_if !== '') {
          inclusionCriteria = obj.include_if.replaceAll(' ', '').split(',')
          // https://stackoverflow.com/questions/16312528/check-if-an-array-contains-any-element-of-another-array-in-javascript
          included = inclusionCriteria.some(r => decisionArray.includes(r))
        } else {
          included = true
        }

        if (obj.exclude_if && obj.exclude_if !== '') {
          exclusionCriteria = obj.exclude_if.replaceAll(' ', '').split(',')
          // https://stackoverflow.com/questions/16312528/check-if-an-array-contains-any-element-of-another-array-in-javascript
          excluded = exclusionCriteria.some(r => decisionArray.includes(r))
        } else {
          excluded = false
        }

        // if users want to disable the question instead of hiding it, it has to be included anyway
        if (debug) console.log('isUsed', obj.title || obj.question);
        if (!included || excluded) {
          if (obj.disable) {
            included = true
            excluded = false
            obj.disabled = true
            if (debug) console.log('isUsed disabled');
          } else {
            delete obj.disabled
            if (debug) console.log('isUsed NOT disabled');
          }
        } else {
          delete obj.disabled
          if (debug) console.log('isUsed NOT EXCLUDED -> NOT disabled');
        }
        if (debug) console.log('isUsed XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
        if (debug) console.log('isUsed Chapter: ' + obj.title);
        if (debug) console.log('isUsed Frage: ' + obj.question);
        if (debug) console.log('isUsed decisionArray: ' + decisionArray);
        if (debug) console.log('isUsed inclusionCriteria: ' + inclusionCriteria);
        if (debug) console.log('isUsed exclusionCriteria: ' + exclusionCriteria);
        if (debug) console.log('isUsed included: ' + included + '   excluded: ' + excluded);
        if (debug) console.log('isUsed obj.disable: ' + obj.disable + '   obj.disabled: ' + obj.disabled);
        if (debug) console.log('isUsed !!included && !excluded: ' + (!!included && !excluded));
        return !!included && !excluded
      }
    },
    // ..######...#######..##.....##.##....##.########
    // .##....##.##.....##.##.....##.###...##....##...
    // .##.......##.....##.##.....##.####..##....##...
    // .##.......##.....##.##.....##.##.##.##....##...
    // .##.......##.....##.##.....##.##..####....##...
    // .##....##.##.....##.##.....##.##...###....##...
    // ..######...#######...#######..##....##....##...
    countChapters: (state) => {
      return state.checklist ? state.checklist.chapters.length : 0
    },

    countFilteredChapters: (state, getters) => {
      return state.checklist ? getters.getFilteredChapters.length : 0
    },
    // .####.##....##..######..####..######...##.....##.########..######...........##....########..########..######..##.....##.##.......########..######.
    // ..##..###...##.##....##..##..##....##..##.....##....##....##....##.........##.....##.....##.##.......##....##.##.....##.##..........##....##....##
    // ..##..####..##.##........##..##........##.....##....##....##..............##......##.....##.##.......##.......##.....##.##..........##....##......
    // ..##..##.##.##..######...##..##...####.#########....##.....######........##.......########..######....######..##.....##.##..........##.....######.
    // ..##..##..####.......##..##..##....##..##.....##....##..........##......##........##...##...##.............##.##.....##.##..........##..........##
    // ..##..##...###.##....##..##..##....##..##.....##....##....##....##.....##.........##....##..##.......##....##.##.....##.##..........##....##....##
    // .####.##....##..######..####..######...##.....##....##.....######.....##..........##.....##.########..######...#######..########....##.....######.
    getInsight: (state, getters) => {
      if (state.checklist) {
        let value = state.checklist.insightsdir ? (getters.getResultData.nrOfQuestions - getters.getResultData.nrOfAnswers) : getters.getResultData.nrOfAnswers
        for (var i = 0; i < state.checklist.insights.length; i++) {
          if (value >= state.checklist.insights[i].above) {
            break
          }
        }
      }
      return state.checklist.insights[i]
    },
    getResult: (state, getters) => {
      if (state.checklist) {
        let value = state.checklist.resultsdir ? (getters.getResultData.maxPoints - getters.getResultData.currentPoints) : getters.getResultData.currentPoints
        for (var i = 0; i < state.checklist.results.length; i++) {
          if (value >= state.checklist.results[i].above) {
            break
          }
        }
      }
      return state.checklist.results[i]
    },
    showListNeutral: state => {
      return state.checklist ? state.checklist.listneutral : false
    },
    getListNeutralText: state => {
      return state.checklist ? state.checklist.listneutraltext : ""
    },
    getListNeutralStyle: state => {
      return state.checklist ? state.checklist.listneutralstyle : "default"
    },
    showListPoints: state => {
      return state.checklist ? state.checklist.listpoints : false
    },
    getListPointsText: (state) => {
      return state.checklist ? state.checklist.listpointstext : ""
    },
    getListPointsStyle: state => {
      return state.checklist ? state.checklist.listpointsstyle : "default"
    },
    showListNoPoints: state => {
      return state.checklist ? state.checklist.listnopoints : false
    },
    getListNoPointsText: state => {
      return state.checklist ? state.checklist.listnopointstext : ""
    },
    getListNoPointsStyle: state => {
      return state.checklist ? state.checklist.listnopointsstyle : "default"
    },
  },
  // .##.....##.##.....##.########....###....########.####..#######..##....##..######.
  // .###...###.##.....##....##......##.##......##.....##..##.....##.###...##.##....##
  // .####.####.##.....##....##.....##...##.....##.....##..##.....##.####..##.##......
  // .##.###.##.##.....##....##....##.....##....##.....##..##.....##.##.##.##..######.
  // .##.....##.##.....##....##....#########....##.....##..##.....##.##..####.......##
  // .##.....##.##.....##....##....##.....##....##.....##..##.....##.##...###.##....##
  // .##.....##..#######.....##....##.....##....##....####..#######..##....##..######.
  mutations: {
    setStepNumber: (state, data) => {
      state.stepNumber = data
    },
    setLoading: (state, data) => {
      state.loading = data
    },
    setUpdating: (state, data) => {
      state.updating = data
    },
    setResultShown: (state, data) => {
      state.checklist.resultshown = data
    },
    setResultData: (state, data) => {
      state.checklist.resultData = data
    },
    setResultLists: (state, data) => {
      state.checklist.resultLists = data
    },
    setDecisionArray: (state, data) => {
      state.checklist.decisionArray = data
    },
    setFilteredChapters: (state, data) => {
      state.checklist.filteredChapters = data
    },
    setUsedChapterIndices: (state, data) => {
      state.checklist.usedChapterIndices = data
    },
    fromLocalStorage: (state) => {
      let jdata = localStorage.getItem(state.appkey)
      if (jdata) {
        state.checklist = JSON.parse(jdata)
      }
    },
    setChecklist: (state, data) => {
      state.checklist = data
    },
    setAnswer: (state, payload) => {
      state.checklist.chapters[payload.c].questions[payload.q].answer = payload.v
    },
    clearAnswers: (state) => {
      for (var c = 0; c < state.checklist.chapters.length; c++) {
        for (var q = 0; q < state.checklist.chapters[c].questions.length; q++) {
          state.checklist.chapters[c].questions[q].answer = null
        }
      }
    }
  },
  // ....###.....######..########.####..#######..##....##..######.
  // ...##.##...##....##....##.....##..##.....##.###...##.##....##
  // ..##...##..##..........##.....##..##.....##.####..##.##......
  // .##.....##.##..........##.....##..##.....##.##.##.##..######.
  // .#########.##..........##.....##..##.....##.##..####.......##
  // .##.....##.##....##....##.....##..##.....##.##...###.##....##
  // .##.....##..######.....##....####..#######..##....##..######.
  actions: {
    INIT_DATA: ({ state, commit, dispatch }) => {
      commit('fromLocalStorage')
      if (!state.checklist) {
        dispatch('FROM_SERVER')
      }
    },
    FROM_SERVER: ({ state, commit, dispatch }) => {
      commit('setLoading', true)
      axios
        .get(state.webservice)
        .then(response => {
          commit('setChecklist', response.data)
          commit('clearAnswers')
          commit('setLoading', false)
          dispatch('UPDATE_DECISION_ARRAY')
          dispatch('TO_LOCAL_STORAGE')
        })
        .catch(error => {
          commit('setLoading', false)
          console.log(error)
        })
    },
    RESET: ({ state, commit, dispatch }) => {
      commit('setUpdating', true)
      commit('setStepNumber', 1)
      commit('clearAnswers')
      axios
        .get(state.webservice
          , {
            // query URL without using browser cache
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            },
          }
        )
        .then(response => {
          commit('setChecklist', response.data)
          commit('setUpdating', false)
          dispatch('UPDATE_DECISION_ARRAY')
          dispatch('TO_LOCAL_STORAGE')
        })
        .catch(error => {
          commit('setLoading', false)
          dispatch('TO_LOCAL_STORAGE')
          console.log(error)
        })
        .finally(() => {

          // announce action to screenreaders
          let el = document.createElement('div');
          let id = 'speak-' + Date.now();
          el.setAttribute('id', id);
          el.setAttribute('aria-live', 'polite');
          el.classList.add('visually-hidden', 'speak');
          document.body.appendChild(el);
          window.setTimeout(function () {
            // read the document title without the th40 name suffix
            document.getElementById(id).innerHTML = 'Antworten gelöscht.';
          }.bind(this), 200)

          window.setTimeout(function () {
            let spokenText = document.getElementsByClassName('speak')
            for (let i = 0; i < spokenText.length; i++) {
              const element = spokenText[i];

              // console.log(element);
              document.body.removeChild(element)
            }
          }, 1000)

          router.push('/')
        })
    },
    TO_LOCAL_STORAGE: ({ state }) => {
      let jdata = JSON.stringify(state.checklist)
      localStorage.setItem(state.appkey, jdata)
    },
    SET_ANSWER: ({ commit, dispatch }, payload) => {
      commit('setAnswer', payload)
      // if (typeof payload.v !== 'number') {
        dispatch('UPDATE_DECISION_ARRAY')
      // }
      dispatch('TO_LOCAL_STORAGE')
    },
    UPDATE_DECISION_ARRAY: ({ state, getters, commit, dispatch }) => {
      if(debug) console.log('UPDATE_DECISION_ARRAY');
      if (state.checklist) {
        // Have to assemble the array twice to consider 
        // changes based on current input
        let decisions = []
        state.checklist.chapters.forEach(chapter => {
          (chapter.questions || []).forEach(question => {
            if (getters.isUsed(question) && !question.disabled) {
              (question.answerOptions || []).forEach(answerOption => {
                if (answerOption.answerType === 'decision' && question.answer === answerOption.answerDecision) {
                  decisions.push(...question.answer.replace(' ', '').split(','))
                }
              })
            }
          })
        })
        commit('setDecisionArray', decisions)

        decisions = []
        state.checklist.chapters.forEach(chapter => {
          (chapter.questions || []).forEach(question => {
            if (getters.isUsed(question) && !question.disabled) {
              (question.answerOptions || []).forEach(answerOption => {
                if (answerOption.answerType === 'decision' && question.answer === answerOption.answerDecision) {
                  decisions.push(...question.answer.replace(' ', '').split(','))
                }
              })
            }
          })
        })
        commit('setDecisionArray', decisions)
        dispatch('UPDATE_FILTERED_CHAPTERS')
      }
    },

    UPDATE_FILTERED_CHAPTERS: ({ state, commit, getters }) => {
      if (debug) console.log('UPDATE_FILTERED_CHAPTERS');
      if (state.checklist) {
        let debug = false
        let chapters = []
        let usedChapterIndices = []

        for (let i = 0; i < state.checklist.chapters.length; i++) {
          const chapter = state.checklist.chapters[i];
          if (debug) console.log('#################### UPDATE_FILTERED_CHAPTERS ###########################')
          if (debug) console.log('UPDATE_FILTERED_CHAPTERS: chapter.title ', chapter.title)
          if (debug) console.log('UPDATE_FILTERED_CHAPTERS: !getters.isUsed(chapter) ', !getters.isUsed(chapter))
          if (debug) console.log('UPDATE_FILTERED_CHAPTERS: chapter.questions.every((question) => (!getters.isUsed(question))) ', chapter.questions.every((question) => (!getters.isUsed(question))))
          if (debug) console.log('UPDATE_FILTERED_CHAPTERS: return ', !(!getters.isUsed(chapter)
            || chapter.questions.every((question) => (!getters.isUsed(question)))));

          if (!(!getters.isUsed(chapter)
            || chapter.questions.every((question) => (!getters.isUsed(question))))) {
            chapters.push(chapter)
            usedChapterIndices.push(i)
          }
        }
        commit('setFilteredChapters', chapters)
        commit('setUsedChapterIndices', usedChapterIndices)
      }
    },

    UPDATE_RESULT_LISTS: ({ state, commit, getters }) => {
      let listNeutral = []
      let listPoints = []
      let listNoPoints = []
      if (state.checklist) {
        let chapters = state.checklist.chapters
        for (var c = 0; c < chapters.length; c++) {
          for (var q = 0; q < chapters[c].questions.length; q++) {

            // AND chapter and question used
            if ((getters.isUsed(chapters[c]) && !chapters[c].disabled
              && getters.isUsed(chapters[c].questions[q])
              && !chapters[c].questions[q].disabled
              && !chapters[c].questions[q].isHint)) {

              let item = {
                'question': chapters[c].questions[q].question,
                'q': q,
                'c': c,
                'link': "/chapter/" + (c) + '#question' + q,
                'path': "/chapter/" + (c),
                'hash': '#question' + q
              }

              // answer of type "number" (no decisions)
              if (typeof chapters[c].questions[q].answer === "number") {
                if (!chapters[c].questions[q].answerOptions) {
                  if (state.checklist.answers[chapters[c].questions[q].answer].neutral) {
                    listNeutral.push(item)
                  } else if (state.checklist.answers[chapters[c].questions[q].answer].points > 0) {
                    listPoints.push(item)
                  } else if (state.checklist.answers[chapters[c].questions[q].answer].points == 0) {
                    listNoPoints.push(item)
                  }
                }
                // outcomes
                else if (chapters[c].questions[q].answerOptions
                  && chapters[c].questions[q].answerOptions[chapters[c].questions[q].answer].answerType === 'outcome') {
                  let item = {
                    'question': chapters[c].questions[q].question,
                    'answer': chapters[c].questions[q].answerOptions[chapters[c].questions[q].answer].answerOutcome,
                    'q': q,
                    'c': c,
                    'link': "/chapter/" + (c) + '#question' + q,
                    'path': "/chapter/" + (c),
                    'hash': '#question' + q
                  }
                  if (chapters[c].questions[q].answerOptions[chapters[c].questions[q].answer].isNeutralOutcome) {
                    listNeutral.push(item)
                  } else if (chapters[c].questions[q].answerOptions[chapters[c].questions[q].answer].pointsOutcome > 0) {
                    listPoints.push(item)
                  } else if (chapters[c].questions[q].answerOptions[chapters[c].questions[q].answer].pointsOutcome == 0) {
                    listNoPoints.push(item)
                  }
                }
              }
              // if there is no answer
              else if (!chapters[c].questions[q].answer) {
                if (chapters[c].questions[q].answerOptions) {
                  // we have to check if there is an outcome answer type
                  let hasOutcomes = chapters[c].questions[q].answerOptions.some((answerOption) => answerOption.answerType === 'outcome')
                  if (hasOutcomes) {
                    listNeutral.push(item)
                  }
                } else {
                  listNeutral.push(item)
                }
              }
            }
          }
        }
      }
      commit('setResultLists', { 'listNeutral': listNeutral, 'listPoints': listPoints, 'listNoPoints': listNoPoints })
    },

    CALCULATE_RESULT: ({ state, commit, getters }) => {
      let nrOfQuestions = 0
      let nrOfAnswers = 0
      let maxPoints = 0
      let currentPoints = 0

      let maxPredefAnswerPoints = Math.max(...state.checklist.answers.map((answer) => {
        return answer.points
      }))
      if (debug) console.log('maxPredefAnswerPoints ', maxPredefAnswerPoints)

      if (state.checklist) {
        let chapters = state.checklist.chapters
        for (var c = 0; c < chapters.length; c++) {
          for (var q = 0; q < chapters[c].questions.length; q++) {
            let question = chapters[c].questions[q]

            // if chapter and question used
            if (getters.isUsed(chapters[c])
              && !chapters[c].disabled
              && getters.isUsed(question)
              && !question.disabled
              && !question.isHint) {
              // console.log(chapters[c], question);

              // if custom answers
              if (question.answerOptions) {

                // add max points for question
                let questionsMax = Math.max(...question.answerOptions.map((answerOption) => {
                  return answerOption.pointsOutcome
                }))
                maxPoints += questionsMax

                // if any of the answerOptions has points, the question is counted
                if (questionsMax > 0) {
                  nrOfQuestions++
                }

                // add currentPoints for answer
                if (typeof question.answer === "number"
                  && question.answerOptions[question.answer].answerType === 'outcome') {
                  currentPoints += question.answerOptions[question.answer].isNeutralOutcome ? 0 : question.answerOptions[question.answer].pointsOutcome

                  // if answer has points, the answer is counted
                  if (typeof question.answerOptions[question.answer].pointsOutcome === 'number') {
                    if (!question.answerOptions[question.answer].isNeutralOutcome) nrOfAnswers++
                  }
                }

              }
              // if standard answer
              else {
                // add max points for question even if not answered
                maxPoints += maxPredefAnswerPoints
                // its a standard question, so increase questions count
                nrOfQuestions++
                // add currentPoints for answer
                if (typeof question.answer === "number") {
                  // do not add neutral points
                  currentPoints += state.checklist.answers[question.answer].neutral ? 0 : state.checklist.answers[question.answer].points
                  if (!state.checklist.answers[question.answer].neutral) nrOfAnswers++
                }
              }
            }
          }
        }
      }
      commit('setResultData', {
        'nrOfQuestions': nrOfQuestions,
        'nrOfAnswers': nrOfAnswers,
        'maxPoints': maxPoints,
        'currentPoints': currentPoints
      })
    },
    SET_RESULT: ({ commit, dispatch }) => {
      commit('setResultShown', true)
      dispatch('TO_LOCAL_STORAGE')
    }
  },
  modules: {
    // not yet
  }
})