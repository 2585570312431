<template>
  <div class="text text-imprint" id="skipTo">
    <div v-html="getTextImprint"></div>
    <router-link :to="{ name: 'chapter'}">zurück zur Check-App</router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'imprint',

  computed: {
    ...mapGetters([
      'getTextImprint'
    ]),
  }
}
</script>